import React from 'react';
import { getComponents } from './item-group';
import {H1} from './text'

export const PageSections = ({pageSections}) => pageSections?.map((pageSection, idx) => <PageSection key={idx} pageSection={pageSection} /> )

export const PageSection = ({pageSection}) => {
    const title = pageSection?.title ? <h2 className='mb-4'>{pageSection.title}</h2> : null

    return (<section>{title}{pageSection?.sectionParagraphs?.map((sectionParagraph, idx) => <SectionParagraph key={idx} sectionParagraph={sectionParagraph} /> )} </section>)
}

export const SectionParagraph = ({sectionParagraph}) => getComponents(sectionParagraph)
