import React, { useState, useEffect, Children } from 'react';
import { SimpleParagraphContent } from '../templates/template-base';
import { PreviewSection, EventPreviewSection, ContactsSection, EventPreviewSectionProps, GetAssetImageAnticolor, SimpleButton } from '../components/section';
import PreviewBox, { ButtonlessPreviewBox, PreviewBoxWithButton } from '../components/preview-box'
import { PAGES_INFO, TEMPLATES_INFO } from '../utils/pages-info';

import {
    Section,
    CampaignSection,
    SectionWithTextAndButton,
    PricesSection,
    ContactSection,
    PreviewSectionProps,
  } from '../components/section';

const mapProperties = (item) => {
    switch(item.__typename) {
        case "ContentfulArticle":
            return {
                imageAsset: item.previewImage,
                title     : item.title,
                lead      : item.previewText,
                linkUrl   : TEMPLATES_INFO.article.path + item.slug,
                linkText  : item.landingPagePreviewLinkText ? "Katso lisää" : undefined
            }
        case "ContentfulLiveEvent":
            const props : EventPreviewSectionProps = {
                bgAsset   : item.background,
                iconAsset : item.icon,
                time      : item.date,
                location  : item.location,
                city      : item.city,
                linkText  : item.linkText,
                linkUrl   : item.linkUrl,
            }

            return props
        case "ContentfulPartner":
            return {
                imageAsset: item.image,
                title     : item.name,
                lead      : item.description,
                linkUrl   : TEMPLATES_INFO.article.path + item.slug,
                linkText  : "Katso lisää"
            }
        case "ContentfulPreviewContent":
            return {
                imageAsset: item.image,
                title     : item.title,
                lead      : item.predescription?.predescription,
                linkUrl   : item.link,
                linkText  : item.linkText
            }
      default:
          return null
      }
}

const Item = ({item, itemId}) => {
    switch(item.__typename) {
      case "ContentfulArticle":
        return <PreviewSection key={"ContentfulArticle" + itemId} {...mapProperties(item)}/>
      case "ContentfulLiveEvent":
        return <EventPreviewSection key={"ContentfulArticle" + itemId} {...mapProperties(item)} />
      case "ContentfulPartner":
        return <PreviewSection key={"ContentfulPartner" + itemId} {...mapProperties(item)}/>
      case "ContentfulPreviewContent":
        return <PreviewSection key={"ContentfulArticle" + itemId} {...mapProperties(item)}/>
    default:
        return <div>Sisältö puuttuu.</div>
    }
  }

export const ItemGroups = ({itemGroups}) => itemGroups.map((itemGroup, idx) =>
    <React.Fragment key={idx}>{getComponents(itemGroup)}</React.Fragment>);

export const ItemGroup = ({itemGroup}) => getItems(itemGroup).map((item, i) =>
    isSimpleParagraph(item) ? <SimpleParagraphContent key={i} contentJSON={item.content?.json}/> : <Item key={i} item={item} itemId={i} />);

export const ItemGroupWithHeaderAndBodyText = ({title, bodyText, itemGroup}) =>
    <TitleAndBodyText title={title} bodyText={bodyText}><ItemGroup itemGroup={itemGroup} /></TitleAndBodyText>;

export const TitleAndBodyText = ({title, bodyText, children}) => (
    <section>
        <header><h1>{title}</h1></header>
        <div className="flex flex-col gap-10 px-12 md:px-0">
        <p>{bodyText}</p>
        {children}
        </div>
    </section>
)

const isSimpleParagraph = (item) => item.hasOwnProperty('content') && item?.__typename === "ContentfulCampaignContentParagraph"

const getItems = (obj) => {
    if(!obj) {
        return []
    }
    if(Array.isArray(obj)) {
        return obj
    }
    if(obj?.items) {
        return obj.items
    }
    if(obj?.content) {
        return [obj]
    }
    return [obj]
}

export const getComponents = (itemGroup) => {

    if(itemGroup?.items?.every(x => x?.__typename === "ContentfulContact")) {
        return (<ContactsSection contacts={getItems(itemGroup)} headerText={itemGroup.title}/>)
    }
    if(itemGroup?.items?.every(x => x?.__typename === "ContentfulPartner")) {
        return (<PartnerSection partners={getItems(itemGroup)}/>)
    }

    return (<ItemGroup itemGroup={itemGroup} />)
}

const PartnerSection = ({partners}) => (<div className="my-4 flex sm:flex-col gap-4">
 {partners.map((partner, i) => <Item item={partner} key={i} /> )}
</div>)